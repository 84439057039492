.fixed-top {
  top: 22px;
}

.dropdown-menu {
  min-width: 30px;
}

.navbar {
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 100ms linear;
}

.navbar span,
.navbar a.nav-link {
  -webkit-transition: color 100ms linear, border-color 100ms linear;
  -ms-transition: color 1000ms linear, border-color 100ms linear;
  transition: color 100ms linear, border-color 100ms linear;
}

.nav-background-clear {
  background: none;
}

.nav-background-clear span,
.nav-background-clear a.nav-link {
  color: white !important;
}

.nav-background-clear a.nav-link.btn-outline-started,
.nav-background-clear .navbar-toggler {
  border-color: white !important;
  color: white !important;
}

.nav-background-light {
  background-color: white !important;
}

.navbar .nav-logo-visible {
  display: inline-block;
}

.navbar .nav-logo-hidden {
  display: none;
}