.banner-container {
  text-align: center;
  background-color: #EDF7FC;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  max-width: 100%;
}

.banner-container h2 {
  font-size: 22px;
  line-height: 33px;
  color: #5a5a5a;
}

.banner-container p {
  font-size: 20px;
  line-height: 30px;
  color: #5bace2;
}

.banner-container p img {
  width: 50px;
  height: 50px;
}
