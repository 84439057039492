nav {
  background: none !important;
}

.section_list h2 {
  color: #474747;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.section_list h2 img {
  width: 19px;
  height: 28px;
  margin-right: 10px;
}

.section_list p {
  color: #797979;
  font-size: 14px;
  line-height: 21px;
}