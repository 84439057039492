.faq-section h1 {
  font-weight: 500;
  font-size: 28px;
  color: #5A5A5A;
  margin-bottom: 40px;
}

.faq-card {
  background-color: #F9F9FE;
  box-shadow: none !important;
}

.faq-question {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.faq-description {
  font-size: 14px;
  line-height: 21px;
  color: #797979;
}